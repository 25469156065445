import {
    Box,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    Stack,
    Text,
} from '@chakra-ui/react'
import { Form } from './Form'

export const App = () => (
    <Box height='100vh' bg='#fcf4f2' display='flex'>
        <Box flexGrow={1} overflow='hidden'>
            <Image src='flowers.png' position='absolute' right='0' />
            <Image
                src='flowers.png'
                position='absolute'
                transform=' rotate(180deg)'
                bottom='0'
            />
        </Box>
        <Modal
            isOpen={true}
            onClose={() => void 0}
            size='2xl'
            blockScrollOnMount
            trapFocus
        >
            <ModalContent
                borderRadius='2xl'
                border='#cf7f70 solid 4px'
                shadow='none'
                mx='4'
                bg='#fcf4f2ee'
            >
                <ModalBody>
                    <Stack
                        maxW='xs'
                        mx='auto'
                        py={{ base: '12', md: '16' }}
                        spacing={{ base: '6', md: '10' }}
                        color='#DC7A6C'
                    >
                        <Stack spacing='3' textAlign='center'>
                            <Text
                                textTransform='uppercase'
                                fontSize='lg'
                                mb='5'
                            >
                                You’re invited to
                            </Text>
                            <Heading
                                as='h1'
                                fontWeight='extrabold'
                                fontSize={{ base: '5xl', md: '7xl' }}
                            >
                                Kaushi’s 60 Shades of Pink
                            </Heading>
                        </Stack>
                        <Form />
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
)
