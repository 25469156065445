import { client } from '.'

const insertResponse = `
mutation ($name: String!, $contact: String!, $dietary: String) {
    insert_responses(objects: [{name: $name, contact: $contact, dietary: $dietary}]) {
        affected_rows
    }
}
`

export async function submit(
    name: string,
    contact: string,
    dietary: string
): Promise<void> {
    if (dietary === 'fail test' || dietary === 'test fail') {
        throw new Error('Testing failure')
    }
    const { error } = await client.graphql.request(insertResponse, {
        name,
        contact,
        dietary,
    })

    if (error) {
        console.error(error)
        throw new Error('Failed to send. See console.')
    }
}

export type OnSubmit = (
    name: string,
    contact: string
) => ReturnType<typeof submit>
