import {
    Button,
    Collapse,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { submit } from './submit'
// @ts-ignore
import { WarningTwoIcon } from '@chakra-ui/icons'

export const Form = () => {
    const [isSaving, setIsSaving] = useState<
        'idle' | 'loading' | 'failed' | 'complete'
    >('idle')

    return (
        <>
            <Collapse in={isSaving === 'idle' || isSaving === 'loading'}>
                <Stack
                    as='form'
                    spacing='3'
                    textTransform='uppercase'
                    onSubmit={async (e) => {
                        setIsSaving('loading')
                        const form = (e.target as any).elements
                        e.preventDefault()
                        try {
                            await submit(
                                form.name.value,
                                form.contact.value,
                                form.dietary.value
                            )
                            setIsSaving('complete')
                        } catch {
                            setIsSaving('failed')
                        }
                    }}
                >
                    <FormControl
                        id='name'
                        isDisabled={isSaving !== 'idle'}
                        isRequired
                    >
                        <FormLabel color='#DC7A6C'>
                            Names of all attendees
                        </FormLabel>
                        <Input
                            type='text'
                            size='lg'
                            fontSize='md'
                            color='black'
                            focusBorderColor='#DC7A6C'
                        />
                    </FormControl>
                    <FormControl
                        id='contact'
                        isDisabled={isSaving !== 'idle'}
                        isRequired
                    >
                        <FormLabel color='#DC7A6C'>
                            Your email or phone
                        </FormLabel>
                        <Input
                            type='text'
                            size='lg'
                            fontSize='md'
                            color='black'
                            focusBorderColor='#DC7A6C'
                        />
                    </FormControl>
                    <FormControl id='dietary' isDisabled={isSaving !== 'idle'}>
                        <FormLabel color='#DC7A6C'>
                            Dietary notes (please include guest names)
                        </FormLabel>
                        <Input
                            type='text'
                            size='lg'
                            fontSize='md'
                            color='black'
                            focusBorderColor='#DC7A6C'
                        />
                    </FormControl>
                    <Button
                        mt='4'
                        type='submit'
                        fontWeight='semibold'
                        textTransform='uppercase'
                        fontSize='md'
                        size='lg'
                        bg='#DC7A6C'
                        isLoading={isSaving === 'loading'}
                        isDisabled={isSaving === 'complete'}
                    >
                        Submit
                    </Button>
                </Stack>
            </Collapse>
            <Collapse in={isSaving === 'complete'}>
                <Text textAlign='center' textTransform='uppercase'>
                    Your attendance has been saved!
                </Text>
            </Collapse>
            <Collapse in={isSaving === 'failed'}>
                <Text
                    textAlign='center'
                    fontWeight='500'
                    fontSize='xl'
                    color='red.600'
                    textTransform='uppercase'
                >
                    <WarningTwoIcon mb='1.5' /> Something went wrong, please
                    contact your inviter!
                </Text>
            </Collapse>
        </>
    )
}
