import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'
import { theme } from '@chakra-ui/pro-theme'
import { ChakraProvider, extendTheme, Theme } from '@chakra-ui/react'
import { NhostClient, NhostProvider } from '@nhost/react'
import { App } from './App'

const container = document.getElementById('root')

if (!container) {
    throw new Error('Failed to find the root element')
}

const root = createRoot(container)

const proTheme = extendTheme(theme)
const extenstion: Partial<Theme> = {
    colors: { ...proTheme.colors, brand: '#DC7A6C' },
    fonts: {
        body: 'Now',
        heading: 'Dream Avenue',
        mono: proTheme.fonts.mono,
    },
}
const myTheme = extendTheme(extenstion, proTheme)

export const client = new NhostClient({
    subdomain: 'vbhcjcvrhjqnltevrzkt',
    region: 'ap-southeast-1',
})

const Login = () => {
    useEffect(() => {
        client.auth.signIn().then(console.info)
    }, [])

    return <App />
}

root.render(
    <StrictMode>
        <ChakraProvider theme={myTheme}>
            <NhostProvider nhost={client}>
                <Login />
            </NhostProvider>
        </ChakraProvider>
    </StrictMode>
)
